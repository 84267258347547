var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{
    'mt-5 mb-0': _vm.$vuetify.breakpoint.smAndDown,
    'mt-5 mb-5': _vm.$vuetify.breakpoint.mdAndUp,
  },attrs:{"id":"tabs"}},[_c('v-tabs',{staticClass:"justify-left padding-for-subtabs",attrs:{"horizontal":_vm.$vuetify.breakpoint.xsOnly,"vertical":_vm.$vuetify.breakpoint.smAndUp,"show-arrows":true,"color":"dark","active-class":"background-accent"},model:{value:(_vm.active_tab),callback:function ($$v) {_vm.active_tab=$$v},expression:"active_tab"}},[_vm._l((_vm.tabs_with_subtabs),function(item,i){return _c('v-tab',{key:i,staticClass:"text-none",class:{ subtab: item.isChild && _vm.$vuetify.breakpoint.smAndUp },attrs:{"link":""}},[(null != item.icon)?_c('v-icon',{attrs:{"left":"","small":_vm.$vuetify.breakpoint.xsOnly}},[_vm._v(_vm._s(item.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(_vm.path_in_translations + "." + item.id + ".title"))+" ")],1)}),_c('v-tabs-slider',{attrs:{"color":"accent"}}),_vm._l((_vm.tabs_with_subtabs),function(item,i){return _c('v-tab-item',{key:i},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-2",class:{
            'mt-3': _vm.$vuetify.breakpoint.xsOnly,
            'pl-3 pr-1': _vm.$vuetify.breakpoint.smAndDown,
            'pl-11 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
          }},[_c('title-text-list-text-transformed-from-locales',{attrs:{"path_in_translations":_vm.path_in_translations + '.' + item.id}}),(null != item.learn_more_route)?_c('v-btn',{staticClass:"dark--text mt-3",attrs:{"color":"primary","to":item.learn_more_route}},[_vm._v(_vm._s(_vm.$t("global.learn_more")))]):_vm._e()],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }