<template>
  <v-container
    id="tabs"
    :class="{
      'mt-5 mb-0': $vuetify.breakpoint.smAndDown,
      'mt-5 mb-5': $vuetify.breakpoint.mdAndUp,
    }"
  >
    <v-tabs
      :horizontal="$vuetify.breakpoint.xsOnly"
      :vertical="$vuetify.breakpoint.smAndUp"
      :show-arrows="true"
      class="justify-left padding-for-subtabs"
      color="dark"
      active-class="background-accent"
      v-model="active_tab"
    >
      <v-tab
        class="text-none"
        v-for="(item, i) in tabs_with_subtabs"
        :key="i"
        :class="{ subtab: item.isChild && $vuetify.breakpoint.smAndUp }"
        link
      >
        <v-icon
          v-if="null != item.icon"
          left
          :small="$vuetify.breakpoint.xsOnly"
          >{{ item.icon }}</v-icon
        >
        {{ $t(path_in_translations + "." + item.id + ".title") }}
      </v-tab>
      <v-tabs-slider color="accent"></v-tabs-slider>

      <v-tab-item v-for="(item, i) in tabs_with_subtabs" :key="i">
        <v-card flat>
          <v-card-text
            class="pt-2"
            :class="{
              'mt-3': $vuetify.breakpoint.xsOnly,
              'pl-3 pr-1': $vuetify.breakpoint.smAndDown,
              'pl-11 pr-10': $vuetify.breakpoint.mdAndUp,
            }"
          >
            <title-text-list-text-transformed-from-locales
              :path_in_translations="path_in_translations + '.' + item.id"
            />

            <v-btn
              v-if="null != item.learn_more_route"
              color="primary"
              class="dark--text mt-3"
              :to="item.learn_more_route"
              >{{ $t("global.learn_more") }}</v-btn
            >
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import TitleTextListTextTransformedFromLocales from "./TitleTextListTextTransformedFromLocales.vue";
export default {
  components: { TitleTextListTextTransformedFromLocales },
  props: {
    data: Array,
    path_in_translations: {
      type: String,
      default: "",
    },
  },

  watch: {
    $route() {
      this.setActiveTab();
    },
  },

  mounted: function () {
    this.setActiveTab();
  },

  data: () => ({
    active_tab: 0,
  }),

  computed: {
    tabs_with_subtabs() {
      var transformed_tabs = [];
      for (let i = 0; i < this.data.length; i++) {
        var tab = this.data[i];
        tab.isChild = false;
        transformed_tabs.push(tab);
        // if the current menu item has subtabs
        if (null != tab.children) {
          // add subtubs
          for (let j_sub = 0; j_sub < tab.children.length; j_sub++) {
            var subtab = tab.children[j_sub];
            subtab.isChild = true;
            transformed_tabs.push(subtab);
          }
        }
      }
      return transformed_tabs;
    },
  },

  methods: {
    async setActiveTab() {
      try {
        if ("" != this.$route.hash) {
          const hash = this.$route.hash.substring(1);
          var tab_n = parseInt(hash);
          this.active_tab = tab_n;

          await this.$vuetify.goTo(0, { duration: 0 });

          if ("content" != hash) {
            this.$vuetify.goTo("#tabs");
          }
        }
      } catch {
        // do nothing
      }
    },
  },
};
</script>

<style scoped>
.subtab {
  margin-left: 3em !important;
  width: 100%;
}

.justify-left .v-tab {
  justify-content: left;
}
.background-accent {
  background-color: #f4f4f4;
}
.v-card .v-card__text {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
}

.v-tabs--vertical > .v-tabs-bar .v-tab {
  border: 1px solid lightgray;
  margin: 0.3em;
}
</style>